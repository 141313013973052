import { useRef, useEffect } from "react";
import ParticlesEngine from "./particles"

type Props = {
    height: number
}

const Background = ({ height }: Props) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        setTimeout(() => {
            const canvas = canvasRef.current
            const context = canvas?.getContext("2d")
            if (canvas && context) {
                var engine = new ParticlesEngine(
                    canvas,
                    context,
                    height,
                    {
                        clickEventOnce: ParticlesEngine.prototype.pushParticles,
                        // clickEvent: ParticlesEngine.prototype.repulseParticle,
                        hoverEvent: ParticlesEngine.prototype.grabParticle,
                        // hoverEvent: ParticlesEngine.prototype.repulseParticle,
                    }
                )
                engine.start()
            }
        }, 10); // 10ms
    }, [canvasRef, height])

    return (
        <div className="absolute top-0 left-0 bottom-0 right-0 -z-50 bg-[--bg]">
            <canvas className="absolute top-0 left-0 -z-50 bg-[--bg]" ref={canvasRef}></canvas>
        </div>
    )
}

export default Background;