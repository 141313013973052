import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Base from "./pages/Base";
import Homepage from "./pages/Homepage";
import BlogDisplay from "./pages/BlogDisplay";
import BlogBase from "./pages/BlogBase";
import NotFound from './pages/NotFound';
import { Blog } from './pages/BlogDisplay'

// sorted most recent to least recent
const blogs: Blog[] = [
    // TODO: fix up, raw thoughts atm
    // TODO: credit olligobber
    // TODO: publish
    // {
    //     filename: "/blogs/option_lang.md",
    //     url: "/blogs/option_lang",
    //     title: "The option monad as a programming language idea",
    //     description: "I invented the option monad oops",
    // },

    // TODO: publish
    // {
    //     filename: "/blogs/church_numerals.md",
    //     url: "/blogs/church_numerals",
    //     title: "Church Numerals in LaTeX",
    //     description: "Implementing church numerals in latex typedef",
    // },

    {
        filename: "/blogs/website_setup.md",
        url: "/blogs/website_setup",
        title: "How I set up this website",
        description: "Why does everyone write this sorta blog lol, anyways this is just a thought dump for now (:",
    },

    {
        filename: "/blogs/my_first_blog.md",
        url: "/blogs/my_first_blog",
        title: "My first blog",
        description: "Testing that md rendering works",
    },
]

function App() {
    return (
        <Router>
            {/* TODO: variable height */}
            <Base height={2150}>
                <Routes>
                    <Route path='/' element={
                        <Homepage />
                    } />

                    <Route path='/blogs' element={
                        <BlogBase blogs={blogs} />
                    } />

                    {blogs.map(blog => (
                        <Route path={blog.url} element={
                            <BlogDisplay blog={blog} />
                        } />
                    ))}

                    <Route path="*" element={
                        <NotFound />
                    } />
                </Routes>
            </Base>
        </Router>
    )
}

export default App;
