import { useNavigate } from "react-router-dom";


const BackButton = ({ className, children }: any) => {
    let navigate = useNavigate();
    return (
        <>
            <button 
                className={"fixed top-4 left-4 mb-1 bg-red " + className}
                onClick={() => navigate(-1)}
            >
                <img className="img-invert" height={30} width={30} src="/back-arrow.png" alt="Go back"></img>
            </button>
            { children }
        </>
    )
}

export default BackButton