import { Colour, RGB, HSL } from "./colour";
import { Mode, ModeWaterfall } from "./modes"

export type Config = {
    particles: {
        density: number
        colourGradient: (val: number) => Colour
        effect_mode: typeof Mode
        shape: {
            type: "circle" | "edge" | "triangle" | "polygon" | "star" | "none"
            stroke: {
                width: number
                colour: Colour
            }
            polygon: {
                nb_sides: number
            }
        }
        opacity: {
            lower: number
            upper: number
        }
        size: {
            lower: number
            upper: number
        }
        line_linked: {
            distance: number
            colour: Colour
            opacity: number
            width: number
        }
        move: {
            speed: { x: number, y: number }
            randomness: number
            bounceBorders: boolean
            bounceParticles: boolean
            gravity: number
        }
    }
    background: {
        margin: number
    }
    modes: {
        grab: {
            distance: number
            gravity: number
            opacity: number
        }
        repulse: {
            distance: number
            gravity: number
        }
        push: {
            particles_nb: number
        }
    }
}


export const CONFIG: Config = {
    "particles": {
        "density": 0.00009, // nodes per pixel squared

        // [0..1] -> purple..pink..red
        // "colourGradient": val => new HSL(270 + 90 * val, 100, 50),
        
        // [0..1] -> red..orange..yellow
        // "colourGradient": val => new HSL(60 * val, 100, 50),
        
        // [0..1] -> light blue..dark blue
        // H=[190..290]
        "colourGradient": val => new HSL(190 + 100 * (val % 1), 100, 50),
        
        "effect_mode": ModeWaterfall,
        "shape": {
            "type": "polygon",
            "stroke": {
                "width": 0,
                "colour": new RGB(0, 0, 0),
            },
            "polygon": {
                "nb_sides": 5
            },
        },
        "opacity": {
            "lower": 0.7,
            "upper": 0.7,
        },
        // "size": {
        //     "lower": 5,
        //     "upper": 5,
        // },
        "size": {
            "lower": 0,
            "upper": 0,
        },
        "line_linked": {
            "distance": 150,
            "colour": new RGB(255, 255, 255),
            "opacity": 0.6,
            "width": 3
        },
        "move": {
            "speed": { x: 0, y: 0 },
            "randomness": 35, // x and y components of speed are +=
            "bounceBorders": false,
            "bounceParticles": false,
            "gravity": 0, // 0.0001, // force between 2 particles is linear with their distance with this coefficient
        },
    },
    "background": {
        "margin": 40,
    },
    "modes": {
        "grab": {
            "distance": 200,
            "gravity": 0, // 0.0001 * 800, // cursor is "800 times more massive"
            "opacity": 1,
        },
        "repulse": {
            "distance": 200,
            "gravity": 0.5, // cursor is "800 times more massive"
        },
        "push": {
            "particles_nb": 2
        },
    }
};