import { useRef } from "react"
import { useEventListener } from 'usehooks-ts'

const Lol = ({ children, ...props }: any) => {
    const ref = useRef<HTMLDivElement>(null);
    // useEventListener("mousemove", (e) => {
    //     let el = ref.current
    //     if (el && el.parentElement) {
    //         let { x, y, width, height } = el.getBoundingClientRect();
    //         if (x <= e.screenX && e.screenX <= x + width && y <= e.screenY && e.screenY <= y + height) {
    //             el.style.marginLeft = `${Math.random() * el.parentElement.offsetWidth}px`;
    //         }
    //     }
    // }, ref);
    useEventListener("mouseenter", () => {
        let el = ref.current;
        if (el && el.parentElement) {
            el.style.marginLeft = `${Math.random() * (el.parentElement.offsetWidth - el.offsetWidth)}px`;
        }
    }, ref)

    return (
        <div {...props}>
            <div ref={ref} className="w-min h-min">
                {children}
            </div>
        </div>
    )
}

export default Lol