import Background from "../components/particles/Background";
import BackButton from "../components/BackButton";
import { ThemeButton } from "../components/ThemeButton";

const Base = ({ children, height }: any) => {
    return (
        <div className="App text-[--text] flex flex-col container mx-auto justify-self-center items-center mb-60"
        >
            <BackButton>
                <ThemeButton>
                    {children}
                </ThemeButton>
            </BackButton>
            <Background height={height || 0} />
        </div>
    )
}

export default Base