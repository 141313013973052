// type Props = {
//     children?: JSX.Element[] | JSX.Element | string
// };

const Button = ({ children, className, ...props }: any) => {
    return (
        <a
            className={"fadeIn rounded-xl p-2 cursor-pointer border-transparent border-2 hover:border-[--border] bg-[--panel] backdrop-blur-md " + className}
            {...props}
        >
            { children }
        </a>
    )
}

export default Button