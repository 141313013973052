import { useEffect, useState, useContext } from "react";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css' // `rehype-katex` does not import the CSS for you
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneLight, oneDark } from "react-syntax-highlighter/dist/esm/styles/prism"

import { ThemeContext } from "../components/ThemeButton";
import Panel from "../components/Panel"

export type Blog = {
    filename: string,
    url: string,
    title: string,
    description: string,
    // dateCreated: string,
}

type Props = {
    blog: Blog
}

function BlogDisplay({ blog }: Props) {
    // TODO: maths typesetting a bit small, too hard to fix?
    // TODO: page title

    const [content, setContent] = useState("")
    const isDark = useContext(ThemeContext)

    useEffect(() => {
        fetch(blog.filename)
            .then(res => res.text())
            .then(text => setContent(text))
    }, [blog.filename])

    return (
        <Panel className="w-full rounded-3xl mt-36 mb-8 p-8">
            <ReactMarkdown
                className="prose prose-neutral dark:prose-invert prose-pre:bg-transparent prose-pre:m-0 prose-pre:p-0"
                rehypePlugins={[rehypeRaw, rehypeKatex]}
                remarkPlugins={[remarkGfm, remarkMath]}
                children={content}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '')

                        return (!inline && match) ? (
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '').trim()}
                                style={isDark ? oneDark : oneLight}
                                language={match[1]}
                                // showLineNumbersInline={true}
                            />
                        ) : (
                            <code {...props} className={className}>
                                {children}
                            </code>
                        )

                    }
                }}
            />
        </Panel>
    )
}

export default BlogDisplay
