// type Props = {
//     children?: JSX.Element[] | JSX.Element | string
//     className?: string,
// }

const Panel = ({ children, className, ...props }: any) => {
    return (
        <div className={`fadeIn max-w-3xl rounded-2xl mt-36 mb-8 p-8 w-full gap-4 bg-[--panel] bg-cover backdrop-blur-xl ` + className } {...props}>
            { children }
        </div>
    )
}

export default Panel