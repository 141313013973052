import Panel from "../components/Panel"

const NotFound = () => {
    return (
        <Panel className="flex flex-col place-items-center">
            <p>Not Found</p>
        </Panel>
    )
}

export default NotFound