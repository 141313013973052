export interface Colour {
    toCanvas(opacity: number): string;
}

export class RGB implements Colour {
    r: number;
    g: number;
    b: number;

    constructor(r: number, g: number, b: number) {
        console.assert(0 <= r && r <= 255 && 0 <= g && g <= 255 && 0 <= b && b <= 255);
        this.r = r;
        this.g = g;
        this.b = b;
    }

    toCanvas(opacity: number): string {
        return `rgba(${this.r}, ${this.g}, ${this.b}, ${opacity})`;
    }
};

export class HSL implements Colour {
    h: number;
    s: number;
    l: number;

    constructor(h: number, s: number, l: number) {
        console.assert(0 <= s && s <= 100 && 0 <= l && l <= 100);
        this.h = (h % 360 + 360) % 360;
        this.s = s;
        this.l = l;
    }

    toCanvas(opacity: number): string {
        return `hsla(${this.h}, ${this.s}%, ${this.l}%, ${opacity})`;
    }
};