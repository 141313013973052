import Lol from "../components/Lol"
import Panel from "../components/Panel"
import Preview from "../components/Preview"
import Button from "../components/Button"

const Homepage = () => {
    return (
        <>
            <Panel className="flex flex-col">
                {/* TODO: put bio here */}
                {/* TODO: put image here */}
                <p className="text-5xl text-700 font-bold mb-5">
                    Ryno
                </p>
                <p className="text-500 text-lg">
                    Lol imagine making pretty stuff before writing content. Couldn't be me.
                </p>
                <Button href="/blogs" className="w-sm p-1 inline">My Blog! (Under construction)</Button>
                <Lol>
                    <Button href="http://admin.ryno.codes/" className="w-sm p-1 inline fa fa-facebook" target="_blank"></Button>
                    <Button href="http://admin.ryno.codes/" className="w-sm p-1 inline fa fa-twitter" target="_blank"></Button>
                    <Button href="http://admin.ryno.codes/" className="w-sm p-1 inline fa fa-linkedin" target="_blank"></Button>
                </Lol>
            </Panel>
            <div className="flex flex-col max-w-3xl w-full grid lg:grid-cols-2 gap-6">
                <Preview
                    className="flex flex-col"
                    href="https://ryno.codes/"
                    img="/homepage/my-website5.jpg"
                >
                    This Website!
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://property-vis.ryno.codes/"
                    img="/homepage/property-vis1.jpg"
                >
                    Property Visualisation
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://lbot.ryno.codes/"
                    img="/homepage/lbot1.jpg"
                >
                    L-Bot
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://spr.ryno.codes/"
                    img="/homepage/spr1.jpg"
                >
                    NoCrumbs.io
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://onecore.ryno.codes/"
                    img="/homepage/onecore1.jpg"
                >
                    Onecore Programming Tutorials
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://zetamac.ryno.codes/"
                    img="/homepage/zetamac.jpg"
                >
                    Multiplayer Zetamac
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://github-vis.ryno.codes/"
                    img="/homepage/github-vis1.jpg"
                >
                    Gitinfo
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://ongi-taxi.ryno.codes/"
                    img="/homepage/ongi-taxi1.jpg"
                >
                    Ongi-Taxi
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://top.gg/bot/911201481917616168"
                    img="/homepage/tactical-chicken1.jpg"
                >
                    Tactical Chicken
                </Preview>
                <Preview
                    className="flex flex-col"
                    href="https://top.gg/bot/447678358138912768"
                    img="/homepage/iltg.png"
                >
                    I Lost The Game
                </Preview>
            </div>
        </>
    )
}

export default Homepage