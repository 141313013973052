import Button from "../components/Button";
import Panel from "../components/Panel"
import { Blog } from "./BlogDisplay"

type Props = {
    blogs: Blog[]
}

const BlogBase = ({ blogs }: Props) => {
    // let o =  
    return (
        <Panel className="w-full rounded-3xl mt-36 mb-8 p-8">
            <p className="text-5xl text-700 font-bold mb-5">
                Blogs
            </p>
            <div className="flex flex-col max-w-3xl w-full grid lg:grid-cols-1 gap-6">
                {
                    blogs.map(blog => {
                        return <Button className="flex flex-col" href={blog.url}>
                            <div>
                                <p className="text-xl font-bold">
                                    {blog.title}
                                </p>
                                <br />
                                <p>{blog.description}</p>
                            </div>
                        </Button>
                    })
                }
            </div>
        </Panel>
    )
}

export default BlogBase
