import { Colour, RGB } from "./colour";
import { CONFIG } from "./config";
import { clamp, randRange } from "./util"

export interface ParticleInterface {
    x: number
    y: number
    vx: number
    vy: number
    radius: number

    opacity: number
    modeContainer: Mode

    distTo(p: ParticleInterface): number
}

export class Mode {
    mode: number
    constructor() {
        this.mode = 0
    }

    getColour(other: Mode): Colour {
        return new RGB(255, 255, 255)
    }

    static updateSingle(p: ParticleInterface, durationElapsed: number, numParticles: number): void { }

    // NOTE: should update both this.mode and other.mode
    static updateWith(p1: ParticleInterface, p2: ParticleInterface, durationElapsed: number, numParticles: number): void { }
}

export class ModeInterp extends Mode {
    mode: number

    constructor() {
        super()
        this.mode = randRange(0, 1)
    }

    getColour(other: Mode): Colour {
        let mid = (this.mode + other.mode) / 2
        if (Math.abs(this.mode - other.mode) > 180) mid = (mid + 180) % 360
        return CONFIG.particles.colourGradient(mid / 360)
    }

    static updateWith(p1: ParticleInterface, p2: ParticleInterface, durationElapsed: number, numParticles: number) {
        let dh = p1.modeContainer.mode - p2.modeContainer.mode
        if (p1.distTo(p2) <= 0.2 * CONFIG.particles.line_linked.distance) {
            let coeff = Math.min(0.5, 0.1 * durationElapsed)

            if ((Math.abs(p1.modeContainer.mode - p2.modeContainer.mode) <= 180) === (p1.modeContainer.mode <= p2.modeContainer.mode)) {
                p1.modeContainer.mode -= dh * coeff; p2.modeContainer.mode += dh * coeff
            } else {
                p1.modeContainer.mode += dh * coeff; p2.modeContainer.mode -= dh * coeff
            }
            p1.modeContainer.mode %= 360
            p2.modeContainer.mode %= 360
        }
    }
}

export class ModeJumpDist extends Mode {
    mode: number

    constructor() {
        super()
        this.mode = randRange(0, 1)
    }

    getColour(other: Mode): Colour {
        let mid = (this.mode + other.mode) / 2
        if (Math.abs(this.mode - other.mode) > 180) mid = (mid + 180) % 360
        return CONFIG.particles.colourGradient(mid / 360)
    }

    static updateWith(p1: ParticleInterface, p2: ParticleInterface, durationElapsed: number, numParticles: number) {
        if (p1.distTo(p2) <= 0.2 * CONFIG.particles.line_linked.distance) {
            p2.modeContainer.mode = p1.modeContainer.mode
        }
    }
}

export class ModeJumpRand extends Mode {
    mode: number

    constructor() {
        super()
        this.mode = randRange(0, 1)
    }

    getColour(other: Mode): Colour {
        let mid = (this.mode + other.mode) / 2
        if (Math.abs(this.mode - other.mode) > 180) mid = (mid + 180) % 360
        return CONFIG.particles.colourGradient(mid / 360)
    }

    static updateWith(p1: ParticleInterface, p2: ParticleInterface, durationElapsed: number, numParticles: number) {
        if (Math.random() < 2 * durationElapsed / numParticles) {
            p2.modeContainer.mode = p1.modeContainer.mode
        }
    }
}

export class ModePropagate extends Mode {
    mode: number

    constructor() {
        super()
        this.mode = randRange(0, 1)
    }

    getColour(other: Mode): Colour {
        let mid = (this.mode + other.mode) / 2
        if (Math.abs(this.mode - other.mode) > 180) mid = (mid + 180) % 360
        return CONFIG.particles.colourGradient(mid / 360)
    }

    static updateWith(p1: ParticleInterface, p2: ParticleInterface, durationElapsed: number, numParticles: number) {
        if (Math.random() < 0.01) {
            // if (Math.floor(p2.modeContainer.mode) % 30 === Math.floor(Date.now() / 1000) % 30) {
            //     this.mode = other.mode
            // }
            if (Math.floor(p1.modeContainer.mode) % 30 === Math.floor(Date.now() / 1000) % 30) {
                p2.modeContainer.mode = p1.modeContainer.mode
            }
        }
    }
}

export class ModeDfs extends Mode {
    mode: number

    constructor() {
        super()
        if (Math.random() < 0.05) {
            this.mode = Date.now() + 2000
        } else {
            this.mode = 0
        }
    }

    getColour(other: Mode): Colour {
        // this.ctx.strokeStyle = Math.min(this.mode, other.mode) !== 0 ? `hsla(0, 100%, 50%, ${opacity_line})` : `hsla(180, 100%, 50%, ${opacity_line})`
        // this.ctx.strokeStyle = CONFIG.particles.colourGradient(Math.max(this.mode, other.mode) !== 0 ? 0 : 1).toCanvas(opacity_line)
        return CONFIG.particles.colourGradient(clamp(
            1 + (Math.max(this.mode, other.mode) - Date.now()) / 9000,
            0,
            1
        ))
    }

    static updateWith(p1: ParticleInterface, p2: ParticleInterface, durationElapsed: number, numParticles: number) {
        if (Math.random() < 2 * durationElapsed / numParticles) {
            if (p1.modeContainer.mode !== 0 && p1.modeContainer.mode < Date.now()) {
                // p1.modeContainer.mode = 0
                p2.modeContainer.mode = Date.now() + randRange(0, 500)
            } else if (p2.modeContainer.mode !== 0 && p2.modeContainer.mode < Date.now()) {
                // p2.modeContainer.mode = 0
                p1.modeContainer.mode = Date.now() + randRange(0, 500)
            }
        }
    }
}

export class ModeBlink extends Mode {
    mode: number

    constructor() {
        super()
        this.mode = Date.now() + randRange(0, 5000)
    }

    getColour(other: Mode): Colour {
        return CONFIG.particles.colourGradient(clamp(
            1 - (Math.abs(this.mode - Date.now()) + Math.abs(other.mode - Date.now())) / 2 / 5000,
            0,
            1,
        ))
    }

    static updateSingle(p: ParticleInterface, durationElapsed: number, numParticles: number) {
        // move
        if (p.modeContainer.mode + 5000 <= Date.now()) {
            p.modeContainer.mode = Date.now() + 5000
        }
    }
}

export class ModeWaterfall extends Mode {
    mode: number

    constructor() {
        super()
        this.mode = Date.now()
    }

    getColour(other: Mode): Colour {
        let mid = (this.mode + other.mode) / 2
        if (Math.abs(this.mode - other.mode) > 180) mid = (mid + 180) % 360
        return CONFIG.particles.colourGradient(mid / 360)
    }

    static updateSingle(p: ParticleInterface, durationElapsed: number, numParticles: number) {
        if (p.y <= 2) p.modeContainer.mode++
    }

    static updateWith(p1: ParticleInterface, p2: ParticleInterface, durationElapsed: number, numParticles: number) {
        if (Math.random() < .8 * durationElapsed) {
            p1.modeContainer.mode = p2.modeContainer.mode = Math.max(p1.modeContainer.mode, p2.modeContainer.mode)
        }
    }
}
