import { createContext } from "react";
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { useLocalStorage } from "usehooks-ts";

export const ThemeContext = createContext(true);

export const ThemeButton = ({ children }: any) => {
    // const [isDark, setTheme] = useState(true);
    const [isDark, setTheme] = useLocalStorage("theme", true);

    document.documentElement.setAttribute("data-theme", isDark ? "dark" : "light");
    if (isDark) {
        document.documentElement.classList.add('dark')
    } else {
        document.documentElement.classList.remove('dark')
    }
    const toggleDarkMode = (checked: boolean) => {
        document.documentElement.setAttribute("data-theme", checked ? "dark" : "light");
        if (checked) {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
        setTheme(checked);
    };
    return (
        <>
            <DarkModeSwitch
                className="fixed top-4 right-4 mb-1"
                checked={isDark}
                onChange={toggleDarkMode}
                size={40}
            />
            <ThemeContext.Provider value={isDark}>
                {children}
            </ThemeContext.Provider>
        </>
    )
}
