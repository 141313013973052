export function clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value));
}

// range of real numbers inclusive inclusive
export function randRange(lower: number, upper: number) {
    return Math.random() * (upper - lower) + lower;
}

// range of integers inclusive inclusive
export function randIntRange(lower: number, upper: number) {
    return Math.floor(Math.random() * (upper - lower + 1)) + lower;
}

export function RGBToHSL(r: number, g: number, b: number) {
    // https://www.30secondsofcode.org/js/s/rgb-to-hsl/
    r /= 255;
    g /= 255;
    b /= 255;
    const l = Math.max(r, g, b);
    const s = l - Math.min(r, g, b);
    const h = s
        ? l === r
            ? (g - b) / s
            : l === g
                ? 2 + (b - r) / s
                : 4 + (r - g) / s
        : 0;
    return {
        h: 60 * h < 0 ? 60 * h + 360 : 60 * h,
        s: 100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
        l: (100 * (2 * l - s)) / 2,
    };
};

CanvasRenderingContext2D.prototype.drawShape = function (startX: number, startY: number, sideLength: number, sideCountNumerator: number, sideCountDenominator: number) {
    // By Programming Thomas - https://programmingthomas.wordpress.com/2013/04/03/n-sided-shapes/
    var sideCount = sideCountNumerator * sideCountDenominator;
    var decimalSides = sideCountNumerator / sideCountDenominator;
    var interiorAngleDegrees = (180 * (decimalSides - 2)) / decimalSides;
    var interiorAngle = Math.PI - Math.PI * interiorAngleDegrees / 180; // convert to radians
    this.save();
    this.beginPath();
    this.translate(startX, startY);
    this.moveTo(0, 0);
    for (var i = 0; i < sideCount; i++) {
        this.lineTo(sideLength, 0);
        this.translate(sideLength, 0);
        this.rotate(interiorAngle);
    }
    // this.stroke();
    this.fill();
    this.restore();
};
