import Button from "./Button"

// type Props = {
//     children?: JSX.Element[] | JSX.Element | string
//     href?: string,
//     img: string
// }

const Preview = ({ children, className, href, img, ...props }: any) => {
    return (
        <Button className={"min-h-[250px] " + className} href={href} {...props} target="_blank">
            <div className="flex justify-center align-center w-full font-bold text-2xl m-2">
                {children}
            </div>
            <img className="cursor-pointer" src={img} alt="" />
        </Button>
    )
}

export default Preview